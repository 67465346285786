
export default {
    Home: {
        Header: {
            HeaderLeftTitle: 'Foton Virtual Card', // 头部标题
            SignInSignUp: 'Sign In/Sign Up',
        },
        // 轮播图
        Slider: {
            Item1: {
                Title: 'The Reliable Virtual Card', // 标题
                Dec: 'Foton Card is the best option if you are looking for high-quality and reliable payment services. We provide reliable services for you.', // 说明
                Btn1: 'Issue Cards', // 按钮
                Btn2: 'Read More', // 
            },
            Item2: {
                Title: 'Multi-platform payments', // 标题
                Dec: 'Foton Card support global multi-platform payment: Facebook ADS / TikTok / Google / Amazon, etc.', // 说明
                Btn1: 'Issue Cards', // 按钮
                Btn2: 'Read More', // 
            },
            Item3: {
                Title: 'Quick and Secure global payments', // 标题
                Dec: 'Foton Card provides quick issuing and friendly management for virtual cards. From here you will realize quick and secure payment.', // 说明
                Btn1: 'Issue Cards', // 按钮
                Btn2: 'Read More', // 
            },
        },
        PageBlock: {
            Block1: {
                MoreThan: 'More Than', // 多于
                Dec: 'Issued Cards', // 已發行卡
                DecInfo: 'Enjoy quick issuing and friendly management.', // 享受快速發行和友好的管理
            },
            Block2: {
                MoreThan: 'Upto', // 取決於
                Dec: 'Fee Discount', // 費用折扣
                DecInfo: 'Very few fee for cards issuing and global payments.', // 發卡和全球支付的費用很少
            },
            Block3: {
                MoreThan: 'More Than', // 多于
                Dec: 'Users Trusts', // 用戶信任
                DecInfo: 'Quick and secure services help you make global payments easily.', // 快速安全的服務可幫助您輕鬆進行全球支付
            }
        },
        // about page about section
        AboutPageAboutSection: {
            Title: 'Quick issuing and Global payments', // 快速發行和全球支付
            // Foton卡與國際發卡機構合作推出虛擬卡服務,涵蓋多種消費場景.用戶可以直接在綫快速申請虛擬卡實現友好管理和快速支付
            Dec: 'Foton Card launched the virtual card service in cooperation with international card issuing organizations, cover a variety of consumption scenarios. Users can quickly apply for virtual cards directly online, and achieve friendly management and fast payment.',
            Btn: 'Learn More', // 瞭解更多
        },
        // mobile section
        MobileSection: {
            SmallTitle: 'FEATURES AND ADVANTAGES', // 特點和優勢
            BigTitle: 'The Reliable Choice', // 可靠的選擇
            MobileApp: 'Mobile App', // 移動應用
            MobileAppDec: 'Instant access to your account on any device using our app.', // 使用我們的應用程序在任何設備上即時訪問您的賬戶
            EnterpriseUsers: 'Enterprise Users', // 企業用戶
            EnterpriseUsersDec: 'Special policies and benefits for enterprise users.', // 針對企業用戶的特殊政策和福利
            GlobalServices: 'Global Services', // 全球服務
            GlobalServicesDec: 'We welcome global clients and provide customized services', // 我們歡迎全球客戶并提供定制服務
            AccountProfile: 'Self service backstage', // 賬戶資料
            AccountProfileDec: 'With just a few clicks, you can easily open, recharge, refund and other operations.', //  在綫注冊您的免費賬戶資料以開始使用我們的服務
            EasyAccountSetup: 'Real time arrival', // 簡單的賬戶設置
            EasyAccountSetupDec: 'Support mainstream payment methods to recharge the account online and arrive at the account in real time.', // 注冊賬戶只需點擊兩次即可編輯賬戶設置
            Support: 'support', // 支持
            SupportDec: 'Support team is always ready to help you solve any issues', // 支持團隊隨時準備幫助您的解決任何銀行問題
        },
        // bottom-grids
        BottomGrids: {
            SmallTitle: 'Our Services', // 我們的服務
            BigTitle: 'Advantages and Details', // 優勢和細節
            Item1: {
                Title: 'Detailed Statistics', // 纖細統計
                Dec: 'Timely and comprehensive payment statistics.', // 及時全面的支付統計
            },
            Item2: {
                Title: 'Email Notification', // 電子郵件通知
                Dec: 'Send important updates and regular statement.', // 發送重要更新和定期聲明
            },
            Item3: {
                Title: 'Cards Management', // 卡管理
                Dec: 'Quickly view and manage virtual cards.', // 快速查看和管理虛擬卡
            },
            Item4: {
                Title: 'Secure Account', // 安全賬戶
                Dec: 'High quality control for network and funds security.', // 網絡和資金安全的高質量控制
            }
        },
        // Issue Your Virtual Cards Now!
        IssueNow: 'Issue Your Virtual Cards Now!', // 立即發行您的虛擬卡
        IssueCard: 'Issue Cards', // 發行卡
        // How to do
        HowToDo: {
            BigTitle: 'How to issue Virtual Cards', // 如何發行虛擬卡
            Item1: {
                Title: 'Online Registration', // 在綫注冊
                Dec: 'Register you account online.', // 在綫注冊您的賬戶
            },
            Item2: {
                Title: 'Filling Out a Form', // 填寫表單
                Dec: 'Fill out a form for neccessary infomations.', // 填寫表單以獲取必要的信息
            },
            Item3: {
                Title: 'Account Deposit', // 賬戶存款
                Dec: 'Recharge your account as instructed', // 按照説明為您的賬戶充值
            },
            Item4: {
                Title: 'Issuing Virtual Cards', // 發行虛擬卡
                Dec: 'Issue virtual cards with a few clicks', // 只需點擊几下即可發行虛擬卡
            }
        },
        // footer
        Footer: {
            // Foton虛擬卡是提供高質量國際支付服務的領先金融機構. 我們的成功歸功於我們忠實的客戶.我們爲您提供可靠的服務
            FotonDec: 'Foton Card provides high-quality international payment services. Our success is attributed to our loyal customers. We provide reliable services for you.',
            Dashboard: 'Dashboard', // 儀表盤
            QuickLinks: 'Quick Links', // 快速鏈接
            Contact: 'Contact', // 聯係
            Legal: 'Legal', // 聲明
            PrivacyPolicy: 'Privacy Policy', // 隱私政策
            CookiePolicy: 'Cookie Policy', // Cookie政策
            WeChatMa: 'Follow Official Wechat',
            TelegramTip: 'Follow Official Telegram：'
        }
    }

}