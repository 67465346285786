
export default {
    Home: {
        Header: {
            HeaderLeftTitle: 'Foton Virtual Card', // 头部标题
            SignInSignUp: '登錄/注冊',
        },
        // 轮播图
        Slider: {
            Item1: {
                Title: '安全可靠的虛擬卡', // 标题
                Dec: '如果您正在尋找高質量和可靠的支付服務,Foton Card是最佳選擇.我們爲您提供可靠的服務', // 说明
                Btn1: '發行卡', // 按钮
                Btn2: '閲讀更多', // 
            },
            Item2: {
                Title: '多平台支付', // 标题
                Dec: 'Foton虛擬卡支持全球多平台支付: Facebook ADS/TikTok/Google/Amazon等。', // 说明
                Btn1: '發行卡', // 按钮
                Btn2: '閲讀更多', // 
            },
            Item3: {
                Title: '快速安全的全球支付', // 标题
                Dec: '為虛擬卡提供快速發行和友好管理服務.在這裏,您將實現快速、安全的支付。', // 说明
                Btn1: '發行卡', // 按钮
                Btn2: '閲讀更多', // 
            },
        },
        PageBlock: {
            Block1: {
                MoreThan: '超過', // 多于
                Dec: '卡發行數量', // 已發行卡
                DecInfo: '享受快速發行和友好的管理。', // 享受快速發行和友好的管理
            },
            Block2: {
                MoreThan: '最高享', // 取決於
                Dec: '費用折扣', // 費用折扣
                DecInfo: '發卡和全球支付的費用很少。', // 發卡和全球支付的費用很少
            },
            Block3: {
                MoreThan: '超過', // 多于
                Dec: '用戶信任', // 用戶信任
                DecInfo: '快速安全的服務可幫助您輕鬆進行全球支付。', // 快速安全的服務可幫助您輕鬆進行全球支付
            }
        },
        // about page about section
        AboutPageAboutSection: {
            Title: '快速發行和全球支付', // 快速發行和全球支付
            // Foton卡與國際發卡機構合作推出虛擬卡服務,涵蓋多種消費場景.用戶可以直接在綫快速申請虛擬卡實現友好管理和快速支付
            Dec: 'Foton Card與國際發卡機構合作推出虛擬卡服務,覆蓋多種消費場景.用戶可以直接在綫快速申請虛擬卡實現快速支付和友好管理',
            Btn: '瞭解更多', // 瞭解更多
        },
        // mobile section
        MobileSection: {
            SmallTitle: '特點和優勢', // 特點和優勢
            BigTitle: '可靠的選擇', // 可靠的選擇
            MobileApp: '移動應用', // 移動應用
            MobileAppDec: '使用我們的應用程序在任何設備上即時訪問您的賬戶', // 使用我們的應用程序在任何設備上即時訪問您的賬戶
            EnterpriseUsers: '企業用戶', // 企業用戶
            EnterpriseUsersDec: '針對企業用戶的特殊政策和福利', // 針對企業用戶的特殊政策和福利
            GlobalServices: '全球服務', // 全球服務
            GlobalServicesDec: '我們歡迎全球客戶并提供定制服務', // 我們歡迎全球客戶并提供定制服務
            AccountProfile: '自助後台', // 賬戶資料
            AccountProfileDec: ' 只需幾次點擊，輕鬆實現開卡、充值、退款等操作', //  在綫注冊您的免費賬戶資料以開始使用我們的服務
            EasyAccountSetup: '實時到賬', // 簡單的賬戶設置
            EasyAccountSetupDec: '支持主流的支付方式進行賬戶在綫充值，實時到賬', // 注冊賬戶只需點擊兩次即可編輯賬戶設置
            Support: '支持', // 支持
            SupportDec: '支持團隊隨時準備幫助您的解決任何問題', // 支持團隊隨時準備幫助您的解決任何銀行問題
        },
        // bottom-grids
        BottomGrids: {
            SmallTitle: '我們的服務', // 我們的服務
            BigTitle: '優勢和細節', // 優勢和細節
            Item1: {
                Title: '精細化統計', // 纖細統計
                Dec: '及時全面的支付統計', // 及時全面的支付統計
            },
            Item2: {
                Title: '電子郵件通知', // 電子郵件通知
                Dec: '發送重要更新和定期賬單', // 發送重要更新和定期聲明
            },
            Item3: {
                Title: '卡管理', // 卡管理
                Dec: '快速查看和管理虛擬卡', // 快速查看和管理虛擬卡
            },
            Item4: {
                Title: '安全賬戶', // 安全賬戶
                Dec: '網絡和資金安全的高質量把控', // 網絡和資金安全的高質量控制
            }
        },
        // Issue Your Virtual Cards Now!
        IssueNow: '立即發行您的虛擬卡!', // 立即發行您的虛擬卡
        IssueCard: '發行卡', // 發行卡
        // How to do
        HowToDo: {
            BigTitle: '如何發行虛擬卡', // 如何發行虛擬卡
            Item1: {
                Title: '在綫注冊', // 在綫注冊
                Dec: '在綫注冊您的賬戶', // 在綫注冊您的賬戶
            },
            Item2: {
                Title: '填寫表單', // 填寫表單
                Dec: '填寫表單以獲取必要的信息', // 填寫表單以獲取必要的信息
            },
            Item3: {
                Title: '賬戶存款', // 賬戶存款
                Dec: '按照説明為您的賬戶充值', // 按照説明為您的賬戶充值
            },
            Item4: {
                Title: '發行虛擬卡', // 發行虛擬卡
                Dec: '只需點擊几下即可發行虛擬卡', // 只需點擊几下即可發行虛擬卡
            }
        },
        // footer
        Footer: {
            // Foton虛擬卡是提供高質量國際支付服務的領先金融機構. 我們的成功歸功於我們忠實的客戶.我們爲您提供可靠的服務
            FotonDec: 'Foton Card提供高質量國際支付服務. 我們的成功歸功於我們忠實的客戶.我們爲您提供可靠的服務',
            Dashboard: '登入', // 儀表盤
            QuickLinks: '快速鏈接', // 快速鏈接
            Contact: '聯係', // 聯係
            Legal: '聲明', // 聲明
            PrivacyPolicy: '隱私政策', // 隱私政策
            CookiePolicy: 'Cookie政策', // Cookie政策
            WeChatMa: '關注官方支援微信',
            TelegramTip: '關注官方支援Telegram：'
        }
    }

}